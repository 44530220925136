import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import { Section, Link } from "../../UI/Common"

import {
  sectionCls,
  bkgdImgCls,
  textBoxClass,
  iconWrapperCls
} from "./TextBoxOnBkgdImg.module.scss"

const TextBoxOnBkgdImg = ({ textBox, backgroundImg, config }) => {

  const { padding } = config || {}
  const { position, sectionHeading, description, button, icons } = textBox

  const orders = position === "left" ? ["first", "last"] : ["last", "first"]


  return (
    <Section padding={padding} className={sectionCls}>
      <GatsbyImage
        className={`d-lg-none ${bkgdImgCls}`}
        style={{ position: "absolute" }}
        alt={backgroundImg.mobile.altText}
        image={getImage(backgroundImg.mobile.localFile)}
      />
      <GatsbyImage
        className={`d-none d-lg-block ${bkgdImgCls}`}
        style={{ position: "absolute" }}
        alt={backgroundImg.desktop.altText}
        image={getImage(backgroundImg.desktop.localFile)}
      />
      <Container>
        <Row>
          <Col
            xs={12}
            lg={{ span: 6, order: orders[0] }}>
            <div className={textBoxClass}>
              <h2 className="py-3">{sectionHeading}</h2>
              <div className="mt-3 mb-5 fs-lg-4">{description}</div>
              <Link link={button.url}>
                <Button size="lg">{button.title}</Button>
              </Link>
              <div className={iconWrapperCls}>
                {icons.map(item => (
                  <GatsbyImage
                    objectFit="scale-down"
                    alt={item.icon.altText}
                    image={getImage(item.icon.localFile)}
                    key={uuidv4()}
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={{ span: 6, order: orders[1] }}
          />
        </Row>
      </Container>
    </Section>
  )
}

export default TextBoxOnBkgdImg
