// extracted by mini-css-extract-plugin
export var bgGrey100 = "TextBoxOnBkgdImg-module--bg-grey-100--d9ae8";
export var bgGrey150 = "TextBoxOnBkgdImg-module--bg-grey-150--a20dc";
export var bgGrey200 = "TextBoxOnBkgdImg-module--bg-grey-200--d4b16";
export var bgGrey300 = "TextBoxOnBkgdImg-module--bg-grey-300--77b31";
export var bgGrey400 = "TextBoxOnBkgdImg-module--bg-grey-400--0517d";
export var bgGrey500 = "TextBoxOnBkgdImg-module--bg-grey-500--83fa3";
export var bgGrey600 = "TextBoxOnBkgdImg-module--bg-grey-600--d25aa";
export var bgGrey700 = "TextBoxOnBkgdImg-module--bg-grey-700--7e5cb";
export var bgGrey800 = "TextBoxOnBkgdImg-module--bg-grey-800--ccd0d";
export var bgGrey900 = "TextBoxOnBkgdImg-module--bg-grey-900--55208";
export var bkgdImgCls = "TextBoxOnBkgdImg-module--bkgdImgCls--e5399";
export var iconWrapperCls = "TextBoxOnBkgdImg-module--iconWrapperCls--0b411";
export var sectionCls = "TextBoxOnBkgdImg-module--sectionCls--81c1e";
export var textBoxClass = "TextBoxOnBkgdImg-module--textBoxClass--f580a";
export var textGrey100 = "TextBoxOnBkgdImg-module--text-grey-100--d9eee";
export var textGrey150 = "TextBoxOnBkgdImg-module--text-grey-150--152f3";
export var textGrey200 = "TextBoxOnBkgdImg-module--text-grey-200--5796e";
export var textGrey300 = "TextBoxOnBkgdImg-module--text-grey-300--e7c33";
export var textGrey400 = "TextBoxOnBkgdImg-module--text-grey-400--b7907";
export var textGrey500 = "TextBoxOnBkgdImg-module--text-grey-500--7f6d0";
export var textGrey600 = "TextBoxOnBkgdImg-module--text-grey-600--dc7e2";
export var textGrey700 = "TextBoxOnBkgdImg-module--text-grey-700--0fb48";
export var textGrey800 = "TextBoxOnBkgdImg-module--text-grey-800--f6a0b";
export var textGrey900 = "TextBoxOnBkgdImg-module--text-grey-900--060c3";